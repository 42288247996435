import React, { useEffect, useState } from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl, prepareParagraph } from '../../../utils/format'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { navigate } from 'gatsby'
import styles from './style.module.sass'

export default ({ header }) => {
  if (!header) return null

  const [safetyModalShow, setSafetyModalShow] = useState(false)

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin)
  }, [])

  const reviewsClickHandler = (path) => {
    if (path === '#reviews') {
      const reviewsElement = document.getElementById('reviews')
      if (reviewsElement) {
        gsap.to(window, {
          duration: 1,
          scrollTo: document.getElementById('reviews')
        })
      }
    } else {
      navigate(path)
    }
  }
  
  const openSafetyModalHandler = () => {
    setSafetyModalShow(true)
  }

  const closeSafetyModalHandler = () => {
    setSafetyModalShow(false)
  }

  return (
    <div className={styles.header}>
      <div className={styles.wrap}>
        <div className={styles.image}>
          <img
            className={styles.imageSrc}
            {...srcSetProps(sanityImageUrl(header.image))}
            alt={header.image?.caption}
          />
        </div>

        <div className={styles.text}>
          <span className={styles.motto}>{header.smallTitle}</span>
          <h1 className={styles.title}>{header.title}</h1>
          {header.rating?.url && (
            <div className={styles.rating} onClick={() => reviewsClickHandler(header.rating.url)}>
              {header.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(header.rating.starsImage)} alt={header.rating?.text}/>}
              {header.rating.text && <p className={styles.ratingText}>{header.rating.text}</p>}
            </div>
          )} 
          <ul className={styles.list}>
            {header.benefits &&
              header.benefits.map((item, index) => (
                <li className={styles.listItem} key={index}>
                  {prepareParagraph(item)}
                </li>
              ))}
          </ul>

          <a href={header.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>
            {header.buttonText}
          </a>

          <ul className={styles.press}>
            {header.press &&
              header.press.map((item, index) => (
                <li className={styles.pressItem} key={index}>
                  <img src={sanityImageUrl(item)} alt={item?.caption} />
                </li>
              ))}
          </ul> 

          {header.importantSafetyModal?.modalText && (
            <>
              <div className={styles.safetyModalLink} onClick={openSafetyModalHandler}>ⓘ <span>{header.importantSafetyModal.modalText}</span></div>
              {safetyModalShow && (
                <div className={styles.safetyModal}>
                  <div className={styles.safetyModalBg} onClick={closeSafetyModalHandler} />
                  <div className={styles.safetyModalWrap}>
                    <div className={styles.safetyModalBox}>
                      <div className={styles.safetyModalClose} onClick={closeSafetyModalHandler} />
                      <p className={styles.safetyModalTitle}>{header.importantSafetyModal.modalTitle}</p>
                      <div className={styles.safetyModalContent} dangerouslySetInnerHTML={{ __html: header.importantSafetyModal.modalContent }} />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}                   
        </div>
      </div>
    </div>
  )
}
