import React from 'react'
import { urlWithSearchParamsHandler, sanityFileUrl } from '../../../utils/format'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ howItWorks }) => {
  if (!howItWorks || howItWorks.isHide) return null

  return (
    <div className={styles.howItWorks}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{howItWorks.title}</h2>

        <div className={styles.items}>
          {howItWorks.items &&
            howItWorks.items.map(item => (
              <div className={styles.item} key={item._key}>
                <div className={styles.top}>
                  <video className={styles.video} preload="auto" autoPlay muted loop playsInline>
                    <source src={sanityFileUrl(item.video)} type="video/mp4" />
                  </video>
                </div>
                <div className={styles.bottom}>
                  <p className={styles.itemTitle}>{item.title}</p>
                  <p className={styles.itemDescription}>{item.description}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
